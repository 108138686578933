.signin-main-section {
    position: relative;
    width: 100%;
    height: 100%;
    /* background-color: rgb(246, 170, 170); */
}

.signin-main-section img {
    position: absolute;
    width: 100%;
    bottom: 10px;
    z-index: -1;
}

.signin-container {
    width: 100%;
    height: 100%;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    /* background-color: red; */
}

.signin-left-section {
    width: 45%;
    height: 70%;
    font-family: Poppins;
    display: flex;
    align-items: end;
    /* background-color: greenyellow; */
}

.signin-left-section h3 {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #737373;
}

.signin-left-section h3 span {
    font-size: 42px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #F0B800;
}

.signin-right-section {
    width: 28%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    /* background-color: greenyellow; */
}

.form-container {
    width: 100%;
    height: 80%;
    border-radius: 10px;
    border: 1px solid #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Roboto;
    /* background-color: #F0B800; */
}

.form-container form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Roboto;
    /* background-color: #F0B800; */
}

.form-container form h3 {
    font-weight: 500;
    margin-bottom: 20px;
}

.form-container form input {
    width: 70%;
    padding: 10px 14px;
    margin-bottom: 20px;
    border-radius: 8px;
    outline: none;
    font-family: Roboto;
    border: 1.5px solid #000;
    background-color: rgba(237, 195, 57, 0.05);
}

.form-container form input::placeholder {
    color: #5C5C5C;
    font-weight: 300;
}

.error-para {
    border: 2.5px solid rgba(222, 57, 57, 0.888) !important;
}

.signin-btn {
    width: 140px;
    height: 35px;
    padding: 10px 0;
    font-size: 12px;
    font-family: Roboto;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 8px;
    cursor: pointer;
    background-color: #000;
}

.signin-btn:hover {
    color: #000;
    background-color: #fff;
    transition: 0.7s ease;
    border: 1.5px solid #000;
}

.forgot-para {
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;
    cursor: pointer;
    color: #8B8B8B;
}
.signin-error-para{
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;
    cursor: pointer;
    color: red;
}

.register-btn {
    width: 140px;
    height: 35px;
    padding: 10px 0;
    font-size: 12px;
    margin-top: 30px;
    font-family: Roboto;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: #000;
    cursor: pointer;
}

.register-btn:hover {
    color: #000;
    background-color: #fff;
    transition: 0.7s ease;
    border: 1.5px solid #000;
}



/* Media Queries */
/* Mobile phones (up to 480px) */
@media (max-width: 480px) {
    .signin-main-section {
        position: relative;
        width: 100%;
        height: 100vh;
        /* background-color: rgb(246, 170, 170); */
    }

    .signin-container {
        width: 100%;
        height: 100vh;
        padding: 0 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        /* background-color: #845454; */
    }

    .signin-main-section img {
        bottom: 10px;
    }

    .signin-left-section {
        width: 90%;
        height: 20%;
        text-align: center;
        justify-content: center;
        align-items: center;
        /* background-color: burlywood; */
    }

    .signin-left-section h3 {
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 1px;
        color: #737373;
    }

    .signin-left-section h3 span {
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 1px;
        color: #F0B800;
    }

    .signin-right-section {
        width: 85%;
        height: 70%;
        /* background-color: #8b8b8b79; */
    }

    .form-container {
        height: 100%;
    }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
    .signin-container {
        width: 100%;
        height: 100%;
        padding: 0 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        /* background-color: #845454; */
    }

    .signin-main-section img {
        bottom: 50px;
    }

    .signin-left-section {
        width: 65%;
        height: 15%;
        text-align: center;
        justify-content: center;
        /* background-color: burlywood; */
    }

    .signin-right-section {
        width: 65%;
        height: 50%;
        /* background-color: #8b8b8b79; */
    }

    .form-container {
        height: 100%;
    }
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
    .signin-main-section img {
        bottom: 40px;
    }

    .signin-left-section {
        width: 50%;
    }

    .signin-right-section {
        width: 48%;
    }

    .form-container {
        height: 50%;
    }
}

/* Large Laptops and Desktops (1025px and above) */
@media (min-width: 1025px) {}