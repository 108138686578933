.header {
  width: 100%;
  height: 70px;
  font-family: Roboto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  background-color: #ffffff;
  padding: 15px 35px;
  border-bottom: 0.3px solid #000;
}

.logout-btn {
  width: 140px;
  height: 35px;
  padding: 10px 0;
  font-size: 12px;
  font-family: Roboto;
  color: #000;
  border: none;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid;
}

.overlay-active-link {
  font-weight: bold;
  color: #ffffff;
  border-bottom: 2px solid #ffffff;
}

.active-link {
  font-weight: bold;
  color: #000000;
  border-bottom: 2px solid #000000;
}

.nav-container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: aqua; */
}

.logo-container {
  min-width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
}

.logo-container img {
  width: 40px;
  height: 45px;
}

.nav-container nav {
  width: 80%;
  padding: 0 0.5rem;
  /* background-color: grey; */
}

.nav-container nav ul {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

.nav-container a {
  color: #000;
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: none;
}

.profile-container img {
  width: 30px;
}

.header-signin-btn {
  min-width: 100px;
  padding: 10px 18px;
  font-size: 12px;
  font-family: Roboto;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #000;
}

.header-signin-btn:hover {
  color: #000;
  background-color: #fff;
  transition: 0.7s ease;
  border: 1.5px solid #000;
}

.overlay-signin-btn {
  min-width: 100px;
  padding: 12px 18px;
  font-size: 16px;
  font-family: Roboto;
  color: #fff;
  border: 1.5px solid #ffffff;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #000;
}

.overlay-signin-btn:hover {
  color: #000;
  background-color: #fff;
  transition: 0.7s ease;
}

.menu-toggle {
  display: none;
  font-size: 28px;
  background: none;
  border: none;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999;
}

.overlay.show {
  opacity: 1;
  visibility: visible;
}

.overlay nav ul {
  list-style: none;
  text-align: center;
  padding: 0;
  margin: 0;
}

.overlay nav ul li {
  margin: 30px 0;
}

.overlay a {
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  text-transform: uppercase;
}

.profile-container-overlay img {
  width: 70px;
  margin-top: 20px;
}

.close-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 26px;
  color: #fff;
  background: rgb(232, 80, 80);
  border: none;
  cursor: pointer;
}

/* Media Queries */
/* Mobile phones (up to 480px) */
@media (max-width: 480px) {
  .header {
    padding: 20px 20px;
  }

  .nav-container nav {
    display: none;
  }

  .nav-menu,
  .profile-container {
    display: none;
  }

  .menu-toggle {
    display: block;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .nav-container nav {
    display: none;
  }

  .nav-menu,
  .profile-container {
    display: none;
  }

  .menu-toggle {
    display: block;
  }
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .nav-container {
    width: 90%;
  }
}

/* Large Laptops and Desktops (1025px and above) */
@media (min-width: 1025px) {}