.convergence-main-section {
    position: relative;
    width: 100%;
    height: 100%;
    /* background-color: rgb(246, 170, 170); */
}

.top-para-container {
    width: 80%;
    margin: auto;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    font-family: Poppins;
    font-weight: 300;
    color: #737373;
    font-size: 13px;
}

.top-para-container p {
    text-align: center;
    letter-spacing: 0.5px;
    line-height: 1.3rem;
}

.main-img-section {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 0;
}

.main-img-section .main-img {
    width: 32rem;
    /* scale: 1.2; */
}

.main-img-section .stake-8-img {
    position: absolute;
    left: 8rem;
    width: 5rem;
}

.main-img-section .stake-9-img {
    position: absolute;
    width: 5rem;
    right: 7rem;
    top: 12rem;
}



/* Media Queries */
/* Mobile phones (up to 480px) */
@media (max-width: 480px) {
    .convergence-main-section {
        position: relative;
        width: 100%;
        height: 100vh;
    }

    .main-img-section {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        margin: 0;
    }

    .main-img-section .main-img {
        width: 24rem;
        padding: 0 10px;
        /* scale: 1.2; */
    }

    .main-img-section .stake-8-img {
        position: absolute;
        left: 2rem;
        width: 4rem;
        bottom: -2rem;
    }

    .main-img-section .stake-9-img {
        position: absolute;
        width: 6rem;
        right: 3rem;
        top: 18rem;
    }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
    .main-img-section {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        margin: 0;
    }

    .main-img-section .main-img {
        width: 46rem;
        /* scale: 1.2; */
    }

    .main-img-section .stake-8-img {
        position: absolute;
        left: 2rem;
        width: 6rem;
        bottom: 0;
    }

    .main-img-section .stake-9-img {
        position: absolute;
        width: 7rem;
        right: 1rem;
        top: 13rem;
    }
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
    .main-img-section {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        margin: 0;
    }

    .main-img-section .main-img {
        width: 48rem;
        /* scale: 1.2; */
    }

    .main-img-section .stake-8-img {
        position: absolute;
        left: 2rem;
        width: 6rem;
        bottom: 0;
    }

    .main-img-section .stake-9-img {
        position: absolute;
        width: 7rem;
        right: 1rem;
        top: 13rem;
    }
}

/* Large Laptops and Desktops (1025px and above) */
@media (min-width: 1025px) {}