.home-main-section {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    /* background-color: rgb(246, 170, 170); */
}

.home-main-container {
    width: 100%;
    height: 100%;
    padding: 15px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: red; */
}

.home-info-section {
    width: 100%;
    font-family: Poppins;
}

.home-info-section h3 {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #737373;
    line-height: normal;
    margin-top: -8px;
}

.home-info-section span {
    font-size: 44px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #F0B800;
}

.home-cards-section {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    /* background-color: rgb(255, 252, 47); */
}

.card {
    width: 170px;
    height: 220px;
    padding: 10px 20px;
    margin-top: 30px;
    margin-right: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Poppins;
    border: 1.5px solid #000;
    border-radius: 10px;
    cursor: pointer;
    background-color: rgba(237, 195, 57, 0.1);
}

.card img {
    width: 50px;
    margin-bottom: 15px;
}

.card h4 {
    text-align: center;
    font-weight: 400;
}




/* Media Queries */
/* Mobile phones (up to 480px) */
@media (max-width: 480px) {
    .home-cards-section {
        justify-content: center;
    }

    .card {
        margin-right: 0px;
    }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
    .card {
        margin-right: 40px;
    }
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
    .card {
        margin-right: 40px;
    }
}

/* Large Laptops and Desktops (1025px and above) */
@media (min-width: 1025px) {}