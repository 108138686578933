.pp-main-section {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    /* background-color: rgb(246, 170, 170); */
}

.pp-detail-info {
    width: 85%;
    margin-top: 2rem;
    margin-bottom: 3rem;
    font-family: Poppins;
}

.pp-detail-info h1 {
    text-align: center;
    margin-bottom: 1rem;
    color: #D8A600;
}

.pp-detail-info h3 {
    color: #D8A600;
}

.pp-detail-info p {
    font-size: 13px;
    color: rgba(115, 115, 115, 1);
    margin-bottom: 1rem;
}

.pp-detail-info ul {
    margin-bottom: 1rem;
}

.pp-detail-info li {
    font-size: 13px;
    color: rgba(115, 115, 115, 1);
}

.pp-detail-info strong {
    font-size: 13px;
    color: #D8A600;
    font-weight: 500;
}