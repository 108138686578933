.food-system-section .main-head {
    font-family: Poppins;
    font-weight: 600;
    text-align: center;
    margin-top: 2rem;
    color: #F0B800;
}

.food-def {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.food-text {
    width: 80%;
    height: 80px;
    text-align: center;
    position: absolute;
    font-family: Poppins;
    overflow-y: auto;
    /* background-color: #dacb97; */
}

.food-text::-webkit-scrollbar {
    display: none;
}

.food-text {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.food-text p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.8px;
    line-height: 1.3rem;
    color: #737373;
}

.food-back {
    width: 100%;
    object-fit: cover;
    z-index: -9;
}

.food-eco-sys {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.food-eco-sys img {
    width: 45rem;
}

.food-eco-txt {
    width: 70%;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.food-eco-txt p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.8px;
    line-height: 1.3rem;
    color: #737373;
}



/* Media Queries */
/* Mobile phones (up to 480px) */
@media (max-width: 480px) {
    .food-system-section .main-head {
        font-size: 28px;
    }
    .food-back {
        display: none;
    }

    .food-def {
        position: relative;
        width: 100%;
        min-height: 30vh;
        margin-top: 1rem;
        margin-bottom: 2rem;
        background-color: rgba(255, 240, 190, 0.5);
    }

    .food-text {
        width: 80%;
        height: 150px;
    }

    .food-eco-sys img {
        width: 23rem;
    }

    .food-eco-txt {
        width: 85%;
    }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
    .food-eco-sys img {
        width: 45rem;
    }

    .food-eco-txt {
        width: 85%;
    }

    .food-scbscribe {
        width: 60%;
    }
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
    .food-eco-sys img {
        width: 50rem;
    }

    .food-eco-txt {
        width: 85%;
    }

    .food-scbscribe {
        width: 50%;
    }
}

/* Large Laptops and Desktops (1025px and above) */
@media (min-width: 1025px) {}