.footer {
  width: 100%;
  min-height: 60px;
  font-family: Roboto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  padding: 0 50px;
  border-top: 0.3px solid #000;
}

.link-container {
  width: 50%;
  /* background-color: red; */
}

.link-container ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

.link-container a {
  color: #000;
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: none;
}

.icons-container {
  width: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icons-container img {
  width: 30px;
}

.icons-container img:nth-child(2) {
  width: 25px;
}

.icons-container img:nth-child(3) {
  width: 25px;
}


/* Media Queries */
/* Mobile phones (up to 480px) */
@media (max-width: 480px) {
  .footer {
    min-height: 100px;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .link-container {
    width: 50%;
  }

  .link-container ul {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .icons-container {
    width: 35%;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .icons-container img {
    width: 25px;
  }

  .icons-container img:nth-child(2) {
    width: 20px;
  }

  .icons-container img:nth-child(3) {
    width: 20px;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .footer {
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .link-container {
    width: 50%;
  }

  .link-container ul {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .icons-container {
    width: 30%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .icons-container img {
    width: 25px;
  }

  .icons-container img:nth-child(2) {
    width: 20px;
  }

  .icons-container img:nth-child(3) {
    width: 20px;
  }
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .link-container {
    width: 60%;
  }

  .icons-container {
    width: 30%;
  }
}

/* Large Laptops and Desktops (1025px and above) */
@media (min-width: 1025px) {}