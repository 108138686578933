.scrollable-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  /* background-color: red; */
}

.scrollable-layout main {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background-color: aquamarine; */
}

.fullscreen-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  /* background-color: red; */
}

.fullscreen-layout main {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background-color: aquamarine; */
}


/* Media Queries */
/* Mobile phones (up to 480px) */
@media (max-width: 480px) {
  .fullscreen-layout {
    height: auto;
    min-height: 100vh;
    overflow-y: auto;
  }

  .fullscreen-layout main {
    flex: unset;
    display: block;
  }

  .scrollable-layout {
    overflow-y: auto;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {}

/* Large Laptops and Desktops (1025px and above) */
@media (min-width: 1025px) {}