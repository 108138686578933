.not-found-section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.not-found-container {
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.not-found-img-container {
    width: 50%;
}

.not-found-detail-container {
    width: 45%;
    font-family: Poppins;
}

.not-found-detail-container h1 {
    font-size: 100px;
    font-weight: 600;
}

.not-found-detail-container h2 {
    font-size: 28px;
    font-weight: 500;
}

.not-found-detail-container p {
    font-size: 14px;
    font-weight: 300;
}

.home-btn {
    font-size: 12px;
    letter-spacing: 1.3px;
    margin-top: 20px;
    padding: 12px 36px;
    border-radius: 6px;
    background-color: transparent;
    border: 2px solid rgb(127, 127, 124);
    cursor: pointer;
}

.home-btn:hover {
    color: #fff;
    background-color: rgb(127, 127, 124);
    transition: 0.7s ease;
}

/* Media Queries */
/* Mobile phones (up to 480px) */
@media (max-width: 480px) {
    .not-found-container {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .not-found-img-container {
        width: 80%;
    }

    .not-found-detail-container {
        width: 90%;
        font-family: Poppins;
    }

    .not-found-detail-container h1 {
        font-size: 55px;
        font-weight: 700;
    }

    .not-found-detail-container h2 {
        font-size: 24px;
    }

    .not-found-detail-container p {
        font-size: 12px;
    }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {}

/* Large Laptops and Desktops (1025px and above) */
@media (min-width: 1025px) {}